import React, { PureComponent } from 'react';
import {SuccessMessage, ValidationMessage} from '../functions/FormUtils.js';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { withTranslation } from 'react-i18next';
import {Row, Col, Form, Button, Modal, Table } from 'react-bootstrap';
import format from 'date-fns/format';
import { enUS, fr, it, es } from 'date-fns/esm/locale'
import i18n from 'i18next';

class WDLManageUnloads extends PureComponent {
    _isMounted = false;

    _locales = {
      en: enUS,
      fr: fr,
      it: it,
      es: es
    }

    empty_load = {
      about: '',
      address: '',
      goods: '',
      unloadDate: '',
      notes: '',
      ddtNumber: '',
      showTime: false,
      unloadDateMillis: null,
    }

    constructor(props) {
        super(props);
        
        this.state = {
          showForm: false,
          selectedUnload: null,
          errorMsg: {},
          successMessage: '',
          showDeleteModal: false,
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectUnload = this.handleSelectUnload.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.addUnload = this.addUnload.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleShowDeleteModal = this.handleShowDeleteModal.bind(this);


    }

    handleSelectUnload(selectedUnload, index) {
      if(selectedUnload){
        selectedUnload.index = index;
      }
      this.setState({selectedUnload}, ()=>!selectedUnload && this.props.scrollUp());
    }

    

    componentWillUnmount() {
        this._isMounted = false;
     }

    componentDidMount() {
      this._isMounted = true;
      
    }

    addUnload() {
      let unload = this.state.selectedUnload;
      this.setState({selectedUnload: null}, this.props.handleAddUnload(unload));
    }

    validateFields = (name, value) => {
      const { t } = this.props;

      let errorMsg = {...this.state.errorMsg}
      errorMsg.appError = '';

      if(name==="name"){
        let nameValid = true;
        errorMsg.name = '';
        if(value.startsWith(" ")) {
          nameValid = false;
          errorMsg.name = t('Errors.invalid_field')
        }else if (value.trim().length < 3) {
          nameValid = false;
          errorMsg.name = t('Errors.field_dimension', {number: 3});
        }

        this.setState({nameValid, errorMsg});

      }

      
    }


    handleChange(e) {

        let target = e.target;
        let value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;
        let selectedUnload = {...this.state.selectedUnload};
        selectedUnload[name]= value;
        this.setState({
          selectedUnload,
        }, this.validateFields(name, value));


    }

    handleClose(){
      this.setState({showDeleteModal: false });
    }
  
    handleShowDeleteModal(){
      this.setState({showDeleteModal: true});
    }
  

    handleDateChange(date) {
      let selectedUnload = {...this.state.selectedUnload};
      if(date) {
        selectedUnload.unloadDateMillis = date.getTime();
      } else {
        selectedUnload.unloadDateMillis = null;
      }

      this.setState({selectedUnload});
    }



    render() {
      const { t } = this.props;
      let formValid = this.state.selectedUnload?.about || this.state.selectedUnload?.unloadDateMillis || this.state.selectedUnload?.address || this.state.selectedUnload?.notes || this.state.selectedUnload?.goods;

        return (
          <Form.Group controlId="loads" className="form_field">
            <Form.Label className="form_field_label">{t('WDL.unloads')}</Form.Label>
            {this.props.shipment?.unloads?.length>0 &&
            <Table striped borderless hover responsive  className="wdl-table">
              <thead>
                <tr style={{backgroundColor: '#ec218c', color: 'white'}}>
                  <th>ID</th>
                  <th>{t('WDL.date')}</th>
                  <th>{t('WDL.about')}</th>
                  <th>{t('WDL.address')}</th>
                  <th>{t('WDL.goods')}</th>
                  <th>{t('WDL.notes')}</th>
                  <th>{t('WDL.ddt_number')}</th>
                </tr>
              </thead>
              <tbody>
                  {this.props.shipment?.unloads?.map((unload, index) => (
                  <tr key={index} >
                    <td onClick={()=>this.handleSelectUnload(unload, index)}>
                      <a>{index} </a>
                    </td>
                    <td>
                    {unload.unloadDateMillis &&
                      <span>{
                        format(
                          new Date(unload.unloadDateMillis),
                          unload.showTime?"dd/MM/yyyy HH:mm":"dd/MM/yyyy",
                          {locale: this._locales[i18n.language]}
                        )}</span>
                        }
                    </td>
                    <td>
                      <span>{unload.about} </span>
                    </td>
                    <td>
                      <span>{unload.address} </span>
                    </td>
                    <td>
                      <span>{unload.goods} </span>
                    </td>
                    <td>
                      <span>{unload.notes} </span>
                    </td>
                    <td>
                      <span>{unload.ddt_number} </span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            }
            {this.state.selectedUnload?
              <Row className="justify-content-md-center">
                <Col md={8} className="wdl-unload-container">
                  <Row>
                    <Col md={12}>
                      <Button type="button" variant="secondary" style={{float: 'right'}} onClick={()=>this.handleSelectUnload(null)}>
                        <i className="fa fa-remove" aria-hidden="true"></i>
                      </Button>
                    </Col>
                  </Row>
                  {this.state.selectedUnload.index!=null &&
                    <Row style={{marginBottom: 20}} className="justify-content-md-center">
                        <span style={{color: '#007bff'}}>{t('WDL.manage_unload', {value: this.state.selectedUnload.index})}</span>
                    </Row>
                  }
                  <Row>
                    <Col md={12}>

                      <Form.Group controlId="date" className="form_field">
                        <Form.Label className="form_field_label">{t('WDL.date')}</Form.Label>
                        <DatePicker
                          key={this.state.selectedUnload.showTime}
                          onChange={this.handleDateChange}
                          showTimeSelect={this.state.selectedUnload.showTime}
                          selected={(this.state.selectedUnload.unloadDateMillis)?new Date(this.state.selectedUnload.unloadDateMillis):null}
                          locale={this._locales[i18n.language]}
                          dateFormat={this.state.selectedUnload.showTime?"dd/MM/yyyy HH:mm":"dd/MM/yyyy"}
                          minDate={this.props.shipment?.shipDateMillis?new Date(this.props.shipment?.shipDateMillis):new Date()}
                        />
                        </Form.Group>
                        <Form.Group controlId="showTime" className="form_field">
                          <Form.Check type="checkbox">
                            <Form.Check.Input type="checkbox" name="showTime" checked={this.state.selectedUnload.showTime} value={this.state.selectedUnload.showTime || ''} onChange={this.handleChange}/>
                            <Form.Check.Label>{t('WDL.show_time')}</Form.Check.Label>
                          </Form.Check>
                        </Form.Group>
                      <Form.Group controlId="about" className="form_field">
                        <Form.Label className="form_field_label">{t('WDL.about')}</Form.Label>
                        <Form.Control type="text" className="form_field_input" name="about" value={this.state.selectedUnload.about || ''} onChange={this.handleChange}/>
                        <ValidationMessage valid={this.state.nameValid} message={this.state.errorMsg.about} />
                      </Form.Group>
                      <Form.Group controlId="address" className="form_field">
                        <Form.Label className="form_field_label">{t('WDL.address')}</Form.Label>
                        <Form.Control type="text" className="form_field_input" name="address" value={this.state.selectedUnload.address || ''} onChange={this.handleChange}/>
                      </Form.Group>
                      <Form.Group controlId="rea" className="form_field">
                        <Form.Label className="form_field_label">{t('WDL.goods')}</Form.Label>
                        <textarea
                          className="form-control"
                          id="goods"
                          name="goods"
                          rows="2"
                          onChange={this.handleChange}
                          value={this.state.selectedUnload.goods || ''}
                          />
                      </Form.Group>
                      <Form.Group className="form_field">
                        <ValidationMessage valid={false} message={this.state.errorMsg.appError} />
                        <SuccessMessage valid={true} message={this.state.successMessage} />
                      </Form.Group>
                      <Form.Group controlId="notes" className="form_field">
                        <Form.Label className="form_field_label">{t('WDL.notes')}</Form.Label>
                        <textarea
                          className="form-control"
                          id="notes"
                          name="notes"
                          rows="2"
                          value={this.state.selectedUnload.notes || ''}
                          onChange={this.handleChange}
                          />
                      </Form.Group>
                      <Form.Group controlId="ddtNumber" className="form_field">
                        <Form.Label className="form_field_label">{t('WDL.ddt_number')}</Form.Label>
                        <Form.Control type="text" className="form_field_input" name="ddtNumber" value={this.state.selectedUnload.ddtNumber || ''} onChange={this.handleChange}/>
                        <ValidationMessage message={this.state.errorMsg.ddtNumber} />
                      </Form.Group>
                      <Form.Row>
                        <Col className="text-center">
                          <Button style={{backgroundColor: '#ec218c'}} variant="primary" type="button" onClick={this.addUnload} disabled={!(formValid)}>{this.state.selectedUnload.index!=null?t('WDL.save'):t('WDL.add')}</Button>
                        </Col>
                        {this.state.selectedUnload.index!=null &&
                          <Col className="text-center">
                            <Button variant="secondary" type="button" onClick={this.handleShowDeleteModal}>{t('WDL.remove')}</Button>
                          </Col>
                        }
                      </Form.Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            :
              <Row className="justify-content-md-center" style={{marginBottom: 80, marginTop: 10}}>
                  <Col className="text-center form-group">
                    <Button style={{backgroundColor: '#ec218c'}} variant="primary" onClick={()=>this.handleSelectUnload(this.empty_load)} >{t('WDL.add_unload')}</Button>
                  </Col>
                </Row>
            }
            <Modal show={this.state.showDeleteModal} animation={false} onHide={this.handleClose}>
                <Modal.Header className="wdl" closeButton>
                </Modal.Header>
                <Modal.Body className="app_aside modal-message" style={{textAlign: 'center'}} >
                    {t('WDL.confirm_delete_unload')}
                </Modal.Body>
                <Modal.Footer className="wdl">
                  <Button variant="primary" onClick={()=>this.setState({showDeleteModal: false, selectedUnload: null}, this.props.handleDelete(this.state.selectedUnload.index))}>
                    {t('Common.confirm')}
                  </Button>
                </Modal.Footer>
              </Modal>
          </Form.Group>




        );
    }
}

export default withTranslation()(WDLManageUnloads);
